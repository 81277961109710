<template>
  <v-container fluid id="hive-management-page">
    <v-row id="hive-management-actions" class="pa-sm-4 pa-md-6 pa-xl-8">
      <v-col cols="12" sm="12" md="6">
        <v-card flat class="my-card" id="management-tasks-card">
          <v-row>
            <v-col cols="6" class="d-sm-none">
              <h2>{{selectedApiary.name}}</h2>
            </v-col>
            <v-col cols="6" sm="12" class="text-right py-0">
              <v-menu offset-y min-width="193" content-class="add-menu">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn icon color="#262338" v-on="on">
                    <v-icon>{{more_icon}}</v-icon>
                  </v-btn>
                </template>
                <MoreMenu 
                  @view-completed="viewCompleted"
                  @view-uncompleted="viewUncompleted"
                  @print-page="printPage"
                />
              </v-menu>
            </v-col>
          </v-row>
          <v-row class="align-center">
            <v-col class="d-none d-sm-block">
              <h2>{{selectedApiary.name}}</h2>
            </v-col>
            <v-col id="search-box-container">
              <v-text-field
                v-model="search"
                :prepend-inner-icon="search_icon"
                :label="$t('search')"
                filled
                flat
                hide-details
                color="#262338"
                background-color="#EFF0F7"
                class="my-input"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-data-table
            id="tasks-table"
            :headers="headers"
            :items="actionItems"
            :search="search"
            hide-default-header
            hide-default-footer
            :mobile-breakpoint="0"
            height="400px"
          >
            <template v-slot:item.checkbox="{ item }">
              <v-icon @click.stop="taskComplete(item)" v-if="!item.completed">
                {{checkbox_off}}
              </v-icon>

              <v-icon @click.stop="taskComplete(item)" v-else>
                {{completed_task_icon}}
              </v-icon>
            </template>
            <template v-slot:item.title="{ item }">
              <div @click.stop="taskDetails(item)"
                class="item-link"
                :class="{'active-task': item.active}">
                <div class="task-title">{{item.title}}</div>
                <div class="task-item-date d-flex" v-if="!item.active">
                  <span class="tasks-calendar-icon">
                    <v-img
                      contain
                      height="18"
                      width="18"
                      :src="`${publicPath}img/icons/`+item.icon+'.svg'"
                    ></v-img>
                  </span>
                  <span>{{item.formattedDate}}</span>
                  <span
                    v-if="(item.notes != '' && item.notes != null)"
                    class="file-icon"
                  >
                    <v-img
                      contain
                      height="18"
                      width="18"
                      src="@/assets/file.svg"
                    ></v-img>
                  </span>
                </div>
              </div>
            </template>
            <template v-slot:item.delete="{ item }">
            <div @click.stop="hideAction(item)">
              <v-icon size="20">
                {{delete_icon}}
              </v-icon>
            </div>
          </template>
            <template v-slot:item.date="{ item }">
              <!-- Keep empty for not showing date on table -->
            </template>
          </v-data-table>
        </v-card>
      </v-col>
      <v-col cols="12" sm="12" md="6" v-if="!isChosenTaskEmpty"
        class="hidden-sm-and-down">
        <ActionDetailsCard @edit-action="openEditActionModal"/>
      </v-col>

      <ActionDetailsModal :visible="!isChosenTaskEmpty" class="hidden-md-and-up"
        @edit-action="openEditActionModal" @close="closeActionDetailsModal"/>

      <EditActionModal :visible="showEditActionModal"
        @close="closeEditActionModal" @save="updateAction"/>

      <SubscriptionModal :visible="showSubscriptionModal" 
        @close="showSubscriptionModal = false"
        @go-to-subscription-page="goToSubscriptionPage"/>

      <v-overlay :value="showLoader">
        <v-progress-circular
          indeterminate
          size="64"
        ></v-progress-circular>
      </v-overlay>

    </v-row>

    <PrintActions />
  </v-container>
</template>

<script>
  import { mapGetters, mapActions } from "vuex";
  import moment from "moment";
  import axios from "axios";

  import MoreMenu from '@/components/HiveManagement/MoreMenu.vue'
  import ActionDetailsCard from '@/components/HiveManagement/ActionDetailsCard.vue'
  import EditActionModal from '@/components/HiveManagement/EditActionModal.vue'
  import ActionDetailsModal from '@/components/HiveManagement/ActionDetailsModal.vue'
  import PrintActions from '@/components/HiveManagement/PrintActions.vue'
  import SubscriptionModal from '@/components/HivesPage/SubscriptionModal.vue'

  export default {
    name: 'HiveManagement',
    components: {
      MoreMenu,
      ActionDetailsCard,
      EditActionModal,
      ActionDetailsModal,
      PrintActions,
      SubscriptionModal
    },
    computed: {
      ...mapGetters([
        "isSubscriptionMissing",
        "selectedApiary",
        "actionItems",
        "actionDetails",
        "editActionItem",
        "completedTitle",
        "inventory"
      ]),
      isChosenTaskEmpty() {
        return Object.keys(this.actionDetails).length == 0;
      }
    },
    data: () => ({
      showLoader: false,
      showEditActionModal: false,
      showActionDetailsModal: false,
      showSubscriptionModal: false,
      search: '',
      search_icon: "$vuetify.icons.search_icon",
      more_icon: "$vuetify.icons.more_icon",
      checkbox_off: "$vuetify.icons.checkbox_off",
      completed_task_icon: "$vuetify.icons.completed_task_icon",
      delete_icon: "$vuetify.icons.delete_icon",
      publicPath: process.env.BASE_URL,
      headers: [
        {text: '', align: 'start', sortable: false, value: 'checkbox'},
        {text: '', align: 'start', sortable: false, value: 'title'},
        {text: '', align: 'end', sortable: false, value: 'delete'},
        {text: '', align: 'd-none', sortable: false, value: 'date'}
      ],
      chosenTask: {},
      appHomeUrl: process.env.VUE_APP_HOME_URL
    }),
    mounted() {
      if (this.inventory.length != 0) {
        let payload = {
          'apiaryId': this.selectedApiary.id
        };

        this.getUncompletedActions(payload);
      }
      this.setIsHiddenApiaryButton(false);
    },
    methods: {
      ...mapActions([
        "getUncompletedActions",
        "getCompletedActions",
        "setActionDetails",
        "setActionsModalTabs",
        "setApiaryButton",
        "setApiaryActionsHistory",
        "setActionActive",
        "setEditActionItem",
        "setIsHiddenApiaryButton",
        "hideGeneralAction"
      ]),
      taskDetails(item) {
        this.setActionActive(item);
        this.setActionDetails(item);
      },
      taskComplete(item) {
        let actionCopy = JSON.parse(JSON.stringify(item));
        this.setEditActionItem(actionCopy).then(() => {
          this.updateAction(true);
        });
      },
      viewCompleted() {
        this.chosenTask = {};
        this.showLoader = true;
        let payload = {
        'apiaryId': this.selectedApiary.id
        };

        this.setActionDetails({});
        this.setEditActionItem({});

        this.getCompletedActions(payload).then(() => {
          this.$gtag.event(
            'View completed actions',
            {
              'event_category': 'Actions'
            }
          );
          this.showLoader = false;
        });
      },
      viewUncompleted() {
        this.chosenTask = {};
        this.showLoader = true;
        let payload = {
          'apiaryId': this.selectedApiary.id
        };

        this.setEditActionItem({});
        this.setActionDetails({});
        this.getUncompletedActions(payload).then(() => {
          this.$gtag.event(
            'View uncompleted actions',
            {
              'event_category': 'Actions'
            }
          );
          this.showLoader = false;
        });
      },
      printPage() {
        window.print();
        this.$gtag.event(
          'Print/Export uncompleted actions',
          {
            'event_category': 'Actions'
          }
        );
      },
      openEditActionModal() {
        if (this.isSubscriptionMissing) {
          this.showSubscriptionModal = true;
        } else {
          let actionCopy = JSON.parse(JSON.stringify(this.actionDetails));
          this.setEditActionItem(actionCopy);

          let result = [
            {
              active: true,
              icon: '',
              name: 'task',
              component: 'task-tab'
            },
            {
              active: false,
              icon: 'box',
              name: 'selected_hives',
              component: 'hives-tab'
            }
          ];

          this.setActionsModalTabs(result).then(() => {
            this.setApiaryButton(true);
            this.showEditActionModal = true;
            document.body.classList.add("modal-open");
          });
        }
      },
      closeEditActionModal() {
        this.setApiaryButton(false);
        this.showEditActionModal = false;
        document.body.classList.remove("modal-open");
      },
      closeActionDetailsModal() {
        this.setActionDetails({});
      },
      updateAction(toggleCompleted = false) {
        this.showLoader = true;
        let completed = toggleCompleted ? !this.editActionItem.completed : this.editActionItem.completed;

        let hives_selected = this.editActionItem.hive_numbers.map((el) => {
          return el.id;
        });


        let actionId = this.editActionItem.id;

        let actionData = {
          hives_selected: hives_selected,
          created_at: this.editActionItem.initiated_at,
          title: this.editActionItem.title,
          notes: this.editActionItem.notes,
          completed: completed ? 1 : 0
        }


        this.updateActionCall(actionId, actionData).then(() => {
          this.$gtag.event(
            'Edit action',
            {
              'event_category': 'Actions'
            }
          );
          this.showLoader = false;
          this.closeEditActionModal();
          if (this.completedTitle == 'viewCompleted') {
            this.viewUncompleted();
          } else {
            this.viewCompleted();
          }
        });
      },
      updateActionCall(actionId, actionData) {
        return new Promise((resolve, reject) => {
          axios.post(`${process.env.VUE_APP_BASE_URL}/api/generalAction/` + actionId, {
            ...actionData
          }).then(res => {
            resolve();
          }).catch(er => {
            console.log(er);
            reject();
          })
        })
      },
      goToSubscriptionPage(plan) {
        this.showLoader = true;
        let url = `${process.env.VUE_APP_BASE_URL}/api/pay-session`;
        let requestData = {
          "success_url" : this.appHomeUrl,
          "cancel_url"  : this.appHomeUrl
        }
        if (plan == 'month') {
          url += '?plan=month';
        } else if (plan == 'year') {
          url += '?plan=year';
        }
        axios.post(url, requestData).then(res => {
          window.location.assign(res.data.checkout);
        }).catch(er => {
          console.log(er);
        });
      },
      hideAction(actionItem) {
        this.showLoader = true;
        this.hideGeneralAction(actionItem.id).then(() => {
          if (this.completedTitle == 'viewCompleted') {
            this.viewUncompleted();
          } else {
            this.viewCompleted();
          }
        });
      }
    }
  }
</script>

<style type="text/css">
  #hive-management-page .my-card {
    padding: 35px 40px;
  }

  #tasks-table {
    margin-top: 20px;
  }

  #tasks-table table td {
    padding: 15px 10px;
    border: none;
  }

  #tasks-table table tr:hover {
    background: #EFF0F7;
  }

  #tasks-table .v-data-footer {
    justify-content: flex-end;
    border: none;
    padding: 0;
  }

  #tasks-table table td:first-child {
    width: 60px;
  }

  #tasks-table table td:last-child {
    width: 0;
  }

  #tasks-table table td:nth-child(3) {
    width: 30px;
    cursor: pointer;
  }

  .item-link {
    cursor: pointer;
  }
  
  #management-tasks-card .my-input {
    border-radius: 8px;
    font-weight: 600;
    font-size: 13px;
    line-height: 22px;
    letter-spacing: 0.25px;
    color: #14142B;
  }

  #management-tasks-card .my-input .v-input__slot::before,
  #management-tasks-card .my-input .v-input__slot::after {
    border:  none;
  }

  #search-box-container .my-input .v-input__icon {
    margin: 0 10px 0 10px;
  }

  .task-title {
    font-weight: 600;
    font-size: 15px;
    line-height: 24px;
    letter-spacing: 0.75px;
  }

  .task-item-date {
    font-size: 12px;
    line-height: 22px;
    letter-spacing: 0.25px;
  }

  .file-icon {
    margin: auto 0 auto 16px;
  }

  .tasks-calendar-icon {
    margin: auto 10px auto 0;
  }

  .active-task {
    border-bottom: 3px solid #EAAC30;
  }

  div.active-task div.task-title {
    padding-bottom: 16px;
  }

  @media screen and (max-width: 960px) {
    #hive-management-page .my-card {
      padding: 35px 25px;
    }

    #management-tasks-card.my-card {
      min-height: unset;
    }
  }

  @media print {
    #app-bar,
    #more-menu,
    #hive-management-actions {
      display: none;
    }

    .main-background {
      padding: 20px 0 0 !important;
    }
  }
</style>
