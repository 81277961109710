<template>
  <div class="my-modal-overlay"
    :class="[show ? 'opened-dialog' : 'closed-dialog']">
    <div class="dialog-container">
      <v-card flat id="edit-action-card" class="my-card">
        <v-row>
          <v-col class="d-flex align-center" cols md="2">
            <div class="inspections-title-icon">
              <v-img
                contain
                height="24"
                width="24"
                class=""
                src="@/assets/lightning.svg"
              ></v-img>
            </div>
            <div>
              <h2>{{$t("actions")}}</h2>
            </div>
          </v-col>
          <v-col md="2" lg="4" class="hidden-sm-and-down"></v-col>

          <v-col class="d-none d-sm-block d-md-none"></v-col>
          <v-col cols="12" sm="8" md="4" lg="3">
            <date-picker
              v-model="actionDate"
              :placeholder="$t('selectDate')"
              value-type="date"
              format="MMMM D, YYYY"
              :clearable="false"
              class="datepicker-container"
              popup-class="my-datepicker-popup"
              input-class="datepicker-btn datepicker-btn-sm"
              :lang="lang"
              :disabled="isTransportActionType"
            >
              <template v-slot:icon-calendar>
                <v-img src="@/assets/calendar.svg"></v-img>
              </template>
            </date-picker>
          </v-col>
          <v-col class="d-none d-sm-block"></v-col>
        </v-row>

        <div class="selected-hives-label">
          <v-icon class="mr-2" color="#000000de">mdi-archive-outline</v-icon>
          {{$t("selected_hives")}}
          <span class="pl-2 pl-sm-4 pl-md-8" v-if="editActionItem.hive_numbers">
            {{editActionItem.hive_numbers.length}}
          </span>
        </div>

        <v-tabs
          grow
          hide-slider
          class="inspections-tabs"
          background-color="#F7F7FC"
          color="#262338"
          v-model="activeTab"
        >
          <v-tab v-for="(tab, index) in actionsModalTabs" :key="index"
            class="my-tab"
            :class="{'active-tab': tab.active}"
            @change="updateTab(index)"
          >
            <v-img
              v-if="tab.active"
              contain
              height="24"
              width="24"
              class="tab-icon"
              :src="`${publicPath}img/icons/`+tab.icon+'_active.svg'"
            ></v-img>
            <v-img
              v-else
              contain
              height="24"
              width="24"
              class="tab-icon"
              :src="`${publicPath}img/icons/`+tab.icon+'_inactive.svg'"
            ></v-img>
            {{$t(tab.name)}}
          </v-tab>
          <v-tabs-items v-model="activeTab" touchless>
            <v-tab-item
              v-for="(tab, index) in actionsModalTabs"
              :key="index"
            >
              <component v-bind:is="tab.component"></component>
            </v-tab-item>
          </v-tabs-items>
        </v-tabs>

        <v-card-actions class="my-card-actions inspections-actions">
          <v-row>
            <v-col md="5" lg="6" xl="7" class="d-none d-sm-none d-md-block"></v-col>
            <v-col cols="12" sm>
              <v-btn
                height="54px"
                outlined
                block
                class="my-btn red-btn"
                @click="cancelBtnAction"
              >
                {{$t("cancel")}}
                <v-spacer></v-spacer>
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-col>
            <v-col cols="12" sm>
              <v-btn
                height="54px"
                depressed
                block
                dark
                @click.stop="saveBtnAction"
                class="my-btn save-btn"
                :disabled="!isValidAction"
              >
                {{$t("save")}}
                <v-spacer></v-spacer>
                <v-icon>{{check_icon}}</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </div>
  </div>
</template>

<script>
  import EditActionHivesTab from '@/components/HiveManagement/EditActionHivesTab';
  import EditActionTaskTab from '@/components/HiveManagement/EditActionTaskTab';
  import {mapGetters, mapActions} from 'vuex';

  import moment from "moment";
  import DatePicker from 'vue2-datepicker';
  import 'vue2-datepicker/locale/el';
  import 'vue2-datepicker/locale/en';
  import 'vue2-datepicker/locale/es';
  import 'vue2-datepicker/index.css';

  export default {
    name: 'ActionsModal',
    components: {
      'hives-tab': EditActionHivesTab,
      'task-tab': EditActionTaskTab,
      DatePicker
    },
    props: {
     visible: Boolean
    },
    computed: {
      ...mapGetters([
        "actionsModalTabs",
        "editActionItem"
      ]),
      show: {
        get () {
          return this.visible
        },
        set (value) {
           if (!value) {
            this.$emit('close');
          }
        }
      },
      lang: {
        get() {
          return this.$i18n.locale;
        }
      },
      actionDate: {
        get () {
          let createdAt = this.$store.state.actions.editActionItem.initiated_at;
          let date = new Date(createdAt);
          return date;
        },
        set(value) {
          let date = moment(value).format("YYYY-MM-DD");
          this.$store.commit('setEditActionItemProp', {prop: 'initiated_at', value: date});
        }
      },
      isEditItemEmpty() {
        return Object.keys(this.editActionItem).length === 0;
      },
      isValidAction() {
        let validationProps = ['title'];
        let isValid = true;

        for (let k = 0; k < validationProps.length; k++) {
          let prop = validationProps[k];
          if (!this.isEditItemEmpty && this.editActionItem[prop] != undefined) {
            if (this.editActionItem[prop] === '') {
              isValid = false;
            }
          }
        }
        return isValid && ! this.isSelectedHivesEmpty;
      },
      isSelectedHivesEmpty() {
        let result = false;
        if (Object.keys(this.editActionItem).length != 0) {
          result = this.editActionItem.hive_numbers.length === 0;
        } else {
          result = true;
        }
        return result;
      },
      isTransportActionType() {
        let result = false;
        if (Object.keys(this.editActionItem).length != 0) {
          result = this.editActionItem.type === 'transfer';
        }
        return result;
      }
    },
    data: () => ({
      publicPath: process.env.BASE_URL,
      activeTab: 0,
      check_icon: "$vuetify.icons.check_icon",
    }),
    methods: {
      ...mapActions(["setActiveTab"]),
      updateTab(index) {
        this.activeTab = index;
        this.setActiveTab(index);
      },
      cancelBtnAction() {
        this.activeTab = 0;
        this.$emit('close');
      },
      saveBtnAction() {
        this.activeTab = 0;
        this.$emit('save');
      }
    }
  }
</script>

<style type="text/css">
  #edit-action-card {
    position: relative;
  }

  @media screen and (max-width: 960px) {
    #edit-action-card.my-card {
      padding: 35px 25px;
    }

    #edit-action-card.my-card .v-tabs.inspections-tabs {
      margin: 15px -25px 0;
    }
  }

  @media screen and (max-width: 600px) {
    #hive-management-page #edit-action-card.my-card {
      padding: 35px 25px 70px;
    }
  }
</style>
