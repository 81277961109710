<template>
  <div class="tab-content" id="edit-action-hives-tab">
    <v-row>
      <v-chip
        v-for="item in editActionItem.hive_numbers"
        class="ma-2 hive-chip"
        color="#EFF0F6"
        text-color="#4E4B66"
      >
        <v-icon
          v-if="editActionItem.type != 'transfer'"
          left
          @click="removeHive(item.id)"
        >mdi-close</v-icon>
        {{item.number}}
      </v-chip>
    </v-row>
  </div>
</template>

<script>
  import {mapGetters, mapActions} from 'vuex';

  export default {
    name: 'ActionsHivesTab',
    computed: {
      ...mapGetters(["editActionItem"])
    },
    methods: {
      ...mapActions(["removeHive"])
    }
  }
</script>

<style type="text/css">
  #edit-action-hives-tab .hive-chip {
    width: 60px;
    height: 28px;
    padding: 3px 10px;
    font-size: 13px;
    line-height: 22px;
    font-weight: 500;
    letter-spacing: 0.25px;
    justify-content: center;
    border-radius: 8px;
  }
</style>