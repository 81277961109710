<template>
  <v-container id="print-actions">
    <h2>{{selectedApiary.name}}</h2>

    <div v-for="(item, index) in actionItems" :key="index" class="top-margin">
      <div class="action-title">{{item.title}}</div>
      <div class="action-date d-flex">
        <span>{{getTaskDetailsDate(item.date)}} </span>
      </div>

      <div v-if="(item.notes != '' && item.notes != null)" class="top-margin">
        <!-- <div class="action-subtitle">Comments</div> -->
        <div class="action-notes">{{item.notes}}</div>
      </div>
      <div class="action-subtitle top-margin">{{$t('hives')}}</div>
      <v-row class="hive-chips-container">
        <v-chip
          v-for="(hiveNumber, hiveNumberIndex) in item.hive_numbers"
          text-color="#262338"
          :key="hiveNumberIndex"
        >
          {{hiveNumber.number}}
          <span v-if="hiveNumberIndex != (item.hive_numbers.length - 1)">, </span>
        </v-chip>
      </v-row>
      <v-divider class="top-margin"></v-divider>
    </div>
  </v-container>
</template>

<script>
  import {mapGetters} from 'vuex';
  import moment from "moment";

  export default {
    name: 'PrintActions',
    computed: {
      ...mapGetters([
        "selectedApiary",
        "actionItems"
      ]),
      lang: {
        get() {
          return this.$i18n.locale;
        }
      }
    },
    data: () => ({
      publicPath: process.env.BASE_URL,
      file_icon: "$vuetify.icons.file_icon",
      edit_icon: "$vuetify.icons.edit_icon",
    }),
    methods: {
      getTaskDetailsDate(date) {
        return moment(date).locale(this.lang).format("ddd D MMMM YYYY");
      }
    }
  };
</script>

<style type="text/css">
  #print-actions {
    display: none;
  }

  .top-margin {
    margin-top: 20px;
  }
  
  #print-actions .action-title {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.75px;
  }

  #print-actions .action-subtitle {
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.75px;
  }

  #print-actions .action-date {
    font-weight: 600;
    font-size: 13px;
    line-height: 22px;
    letter-spacing: 0.25px;
    color: #6E7191;
  }

  #print-actions .action-notes {
    font-weight: 500;
    font-size: 13px;
    line-height: 22px;
    letter-spacing: 0.25px;
    color: #14142B;
  }

  #print-actions .hive-chips-container {
    padding: 5px 0;
  }

  #print-actions .hive-chip {
    width: 60px;
    height: 28px;
    padding: 3px 10px;
    font-size: 13px;
    line-height: 22px;
    font-weight: 600;
    letter-spacing: 0.25px;
    justify-content: center;
    border: 1px solid #262338;
    border-radius: 8px;
    background-color: #ffffff;
    margin: 8px;
  }

  @media print {
    #print-actions {
      display: block;
    }

    #my-app .v-main {
      padding: 20px 0 0 !important;
    }
  }
</style>